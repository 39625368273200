/* global ScrollTrigger */
import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'gutenberg-theme',
            'disable-default-analytics': true,
            'accordion-month-header': { arrowSvgName: 'icon-caret-down' },
            'calendar-week-header': { headerFormat: 'ddd, <br>MMM D' },
            'calendar-week-events': { eventFormat: 'h:mma' },
        });
    });

    $on('toggle-month', () => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 500);
    });

    $on(TicketCalendar.VUE_RENDERED, () => {
        ScrollTrigger.refresh();
    });
});
