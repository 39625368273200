import { $, addAction, LAYOUT } from '@situation/setdesign.util';

addAction(LAYOUT, () => {
    $('.gradient-behind-image').each((i, el) => {
        const backgrounds = [];
        $(el)
            .find('.vc-single-image__image:visible')
            .each((j, image) => {
                const imageRect = image.getBoundingClientRect();
                const containerRect = el.getBoundingClientRect();
                // Calculate center position relative to the container
                const centerX = Math.round(
                    imageRect.left - containerRect.left + imageRect.width / 2,
                );
                const centerY = Math.round(imageRect.top - containerRect.top + imageRect.height);
                const gradientWidth = Math.round(imageRect.width * 1.5);
                const gradientHeight = Math.round(imageRect.height * 2);
                // Set radial gradient position
                backgrounds.push(
                    `radial-gradient(${gradientWidth}px ${gradientHeight}px at ${centerX}px ${centerY}px, rgb(244, 122, 92) 0%, rgba(244, 122, 92, 0) 100%)`,
                );
            });

        // eslint-disable-next-line no-param-reassign
        el.style.backgroundImage = backgrounds.join(', ');
    });
});
